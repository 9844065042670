import '../App.css';
import ImageCarousel from '../components/image-carousel';
import InvitationCounter from '../components/invitation-counter';
import OlaUberNavigation from '../components/ola-uber-navigation';
import { BRIDE_CONTENT, GROOM_CONTENT, HOW_WE_MET_CONTENT } from '../constant';

const Home = () => {
  return (
    <div className="home">
      <InvitationCounter />
      <div className="detailsCont">
        <div className="groomScrollCont">
          <div className="textCont">
            About Groom
          </div>
          <div className="flexCont">
            <div className="soloImgCont">
              <img src={"images/my-solo.jpeg"} className="soloImg"/>
            </div>
            <div className="aboutTextCont">
              {GROOM_CONTENT}
            </div>
          </div>
        </div>
      </div>
      <div className="detailsCont">
        <div className="brideScrollCont">
          <div className="textCont flexEnd">
            About Bride
          </div>
          <div className="flexCont">
            <div className="aboutTextCont">
              {BRIDE_CONTENT}
            </div>
            <div className="soloImgCont">
              <img src={"images/aditi-solo.jpeg"} className="soloImg2"/>
            </div>
          </div>
        </div>
      </div>

      <div className="howWeMetCont">
        <div className="textCont">
          How We Met
        </div>
        <div className="howWeMetText">
            {HOW_WE_MET_CONTENT}
          </div>
      </div>
 
      <div className="textCont">
        Glimpses
      </div>
      <ImageCarousel />
      <div className="venueCont">
        <div style={{border: 'solid #c9b375', borderWidth: '2px', borderRadius: '50%'}}/>
        <hr/>
        <div className="venue-text-1">
          Followed By Dinner and Gathering At
        </div>
        <div className="venue-text-2">
          Satya Mahal Palace
        </div>
        <div className="venue-text-3">
         Ajmer Road, Jaipur
        </div>
        <hr />
        <div style={{border: 'solid #c9b375', borderWidth: '2px', borderRadius: '50%'}}/>
      </div>
      <OlaUberNavigation />
    </div>
  );
}

export default Home;
