import '../App.css';

const ImageCarousel = () => {

  return (
    <div id="slideshow">
      <div class="container">
      <img src={"images/hand-rings.jpeg"}/>
        <img src={"images/together.jpeg"}/>
        <img src={"images/divya-special.jpeg"}/>
        <img src={"images/my-parents.jpeg"}/>
        <img src={"images/aditi-parents.jpeg"}/>
        <img src={"images/my-family.jpeg"}/>
        <img src={"images/my-nanihal.jpeg"}/>
        <img src={"images/aditi-bua-fufaji.jpeg"}/>
        <img src={"images/aditi-mama-mami.jpeg"}/>
        <img src={"images/all-kids.jpeg"}/>
      </div>
    </div>
  );
}

export default ImageCarousel;
