import '../App.css';

const OlaUberNavigation = () => {

  const onOlaSelect = () => {
    window.open("https://olawebcdn.com/assets/ola-universal-link.html?utm_source=xapp_token&landing_page=bk&drop_lat=26.912119&drop_lng=75.788568", '_blank')
  }

  const onUberSelect = () => {
    window.open("https://m.uber.com/ul/?action=setPickup&client_id=N%2CA&pickup=my_location&dropoff[formatted_address]=Satya%20Mahal%20Palace%20(Mysore%20House)%2C%20Jacob%20Road%2C%20Mysore%20House%2FAchrol%20House%20Colony%2C%20Madrampur%2C%20Civil%20Lines%2C%20Jaipur%2C%20Rajasthan%2C%20India&dropoff[latitude]=26.912119&dropoff[longitude]=75.788568", '_blank')
  }

  const onViewOnMapSelect = () => {
    window.open('https://maps.app.goo.gl/7tVXA7swboUkkBbx6', '_blank')
  }

  return (
    <div className="footerNav">
      <button className="btn mapsBtn" onClick={onViewOnMapSelect}>
        <img src={'map-image.jpeg'} className="olaImg"/>
        View On Maps
      </button>
      <footer className="footerCont">
        <button className="btn uberBtn" onClick={onUberSelect}>
          Book An Uber
        </button>
        <button className="btn olaBtn" onClick={onOlaSelect}>
          <img src={'ola-image.png'} className="olaImg"/>
          Book An Ola
        </button>
      </footer>
    </div>
  );
}

export default OlaUberNavigation;
