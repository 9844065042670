import { useEffect, useRef, useState } from 'react';
import '../App.css';

const InvitationCounter = () => {
  const [daysInterval, setDaysInterval] = useState('00')
  const [hoursInterval, setHoursInterval] = useState('00')
  const [minutesInterval, setMinutesInterval] = useState('00')
  const [secondsInterval, setSecondsInterval] = useState('00')

  let interval = useRef()

  const startTimer = () => {
    
    const countDownDate = new Date("28 November 2023 08:00 PM").getTime()
    interval = setInterval(() => {
      const dateDiff =  countDownDate - Date.now()

      const days = Math.floor(dateDiff / (1000 * 60 * 60 * 24))
      const hours = Math.floor(dateDiff % (1000 * 60 * 60 * 24) / (1000 * 60 * 60))
      const minutes = Math.floor(dateDiff % (1000 * 60 * 60) / (1000 * 60))
      const seconds = Math.floor(dateDiff % (1000 * 60) / 1000)

      if (dateDiff < 0) {
        clearInterval(interval.current)
      } else {
        setDaysInterval(days > 9 ? days : `0` + days)
        setHoursInterval(hours > 9 ? hours : `0` + hours)
        setMinutesInterval(minutes > 9 ? minutes : `0` + minutes)
        setSecondsInterval(seconds > 9 ? seconds : `0` + seconds)
      }
    }, 1000)
  }

  useEffect(() => {
    startTimer()
  }, [])

  return (
    <div className="invitationCont">
      <div className="invitationText">
        Wedding Day Countdown for 
      </div>
      <div className="invitationNameCont">
        <div className="nameCont">
          Pulkit
        </div>
        <div className="andCont">
          {"&"}
        </div>
        <div className="nameCont">
          Aditi
        </div>
      </div>
      <div className="dateCont">
        28 <sup>th</sup> November 2023
      </div>
      <div className="timerContList">
        <div className="timerCont">
          <div className="timerVal">
            {daysInterval}
          </div>
          <div>
            Days
          </div>
        </div>
        <div className="timerCont">
          <div className="timerVal">
            {hoursInterval}
          </div>
          <div>
            Hours
          </div>
        </div>
        <div className="timerCont">
          <div className="timerVal">
            {minutesInterval}
          </div>
          <div>
            Minutes
          </div>
        </div>
        <div className="timerCont">
          <div className="timerVal">
            {secondsInterval}
          </div>
          <div>
            Seconds
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvitationCounter;
